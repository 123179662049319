<!-- 查看周期作业 -->
<template>
    <div class="cycle_page">
        <el-dialog title="查看作业" :visible.sync="dialogFormVisible">
            <el-timeline >
                <el-timeline-item v-for="(item,index) in detail" :key="index" :timestamp="`第${item.periods}${period_text}`" placement="top" :type="item.now_time?'primary':''">
                    <h4>作业信息：</h4>
                    <el-card>
                        <el-row>
                            <el-col :span="12" style="font-weight: 700;">阅读/应试:  {{ item.number }}</el-col>
                            <el-col style="text-align: right;" :span="12"><el-button @click="doway_(item.periods)" type="primary" size="mini" class="el-icon-mouse"> 查看完成情况</el-button></el-col>
                        </el-row>
                        
                        <h4>完成人数: {{ item.submit_number }}</h4>
                        <h4>时间周期：{{ item.starttime }} 至 {{ item.endtime }}</h4>
                        <!-- <div v-for="(value,k) in item.item" :key="k">
                            <span >{{ value.type == 0?'阅读在线':'应试英语' }}: </span>
                            <span>{{ value.title }}</span>
                        </div> -->

                        <div class="work_detail">
                            <el-col v-for="(value,k) in item.item" :key="k" :span="24">
                                <el-card shadow="hover">
                                    <div @click="doWork(value.wid,value.periods,value.type,value.z_type)" >
                                        <span >{{ value.type == 0?'阅读在线':'应试英语' }}</span>
                      
                                        <span >{{ value.title }}</span>
                                    </div>
                                </el-card>
                            </el-col>
                        </div>
                
                    </el-card>
                </el-timeline-item>

            </el-timeline>
        </el-dialog>

        <el-dialog :title="`第 ${periods} 周期作业完成情况`" :visible.sync="dowaydialogFormVisible" @close="dowayClose">
            
            <el-table
                :data="student"
                style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名">
                </el-table-column>
                <el-table-column
                    prop="stuid"
                    label="学号">
                </el-table-column>
                <el-table-column
                    prop="number"
                    label="完成篇数">
                </el-table-column>
                <el-table-column
                    prop="fraction"
                    label="得分">
                </el-table-column>
            </el-table>
        
        </el-dialog>
    </div>
</template>

<script>
import { param } from "jquery";
import Http from "../src/apis/work";
export default {
    data(){
        return{
            dialogFormVisible:false,
            dowaydialogFormVisible:false,
            detail:[],
            period_text:'',
            periods:0,
            task_id:'',
            plan_id:'',
            student:[]
        }
    },

    methods:{
        //  查看做题情况
        doway_(periods){
            this.periods = periods
            var params = {}
            params.task_id = this.task_id
            params.plan_id = this.plan_id
            params.periods = periods
            Http.doway(params).then((res) => {
                this.dowaydialogFormVisible = true
                this.dialogFormVisible = false
                this.student = res.data
            });
        },

        dowayClose(){
            this.dowaydialogFormVisible = false
            this.dialogFormVisible = true
        },

        viewWorkDetail(task_id,period_text,plan_id){
            this.period_text = period_text
            var params = {}
            this.task_id = task_id
            this.plan_id = plan_id
            params.task_id = task_id
            params.plan_id = plan_id
            Http.getWorkDetail(params).then((res) => {
                this.detail = res.data
                this.dialogFormVisible = true
            });
        },
        
    }
}
</script>
<style scoped>
.work_dialog_box span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;

    color: #000000;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 13px;
    border-radius: 3px;
    margin-right:10px;
}
.cycle_page >>> .el-timeline-item__timestamp{
    color: #409EFF !important;
    font-size: 14px;
    font-weight: 700;
}
.work_detail >>> .el-card__body{
    padding: 5px;
}
.work_detail >>> .el-col{
    margin-bottom: 10px;
}
</style>