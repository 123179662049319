import axios from '../services/request'

export const Upload = (data = {}) => axios.get('api/common/upload',data);


// 我的收藏
export const Index = (data = {}) => axios.get('api/collection/index',data);

// 我的分享
export const Share = (data = {}) => axios.get('api/collection/share',data);
// 根据班级id获取班级信息
export const getPlan = (data = {}) => axios.get('api/common/getPlan',data);

// 搜索
export const search = (data = {}) => axios.get('api/search/index',data);

// 隐私条款
export const Privacy = (data = {}) => axios.get('api/common/privacy',data);

// 商务合作
export const Cooperation = (data = {}) => axios.get('api/common/cooperation',data);

// 商务合作
export const Overview = (data = {}) => axios.get('api/common/aboute',data);




export default{
    Upload,
    Index,
    Share,
    getPlan,
    search,
    Privacy,
    Cooperation,
    Overview
}