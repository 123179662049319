<template>
    <div class="page">
        <layout>
            <div class="work_body">
                <div class="top_label">
                    <span class="lable_name">班级管理</span>

                </div>
                <div class="label_button_box">
                    <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
                    <div class="label_button_box_left">
                        <el-button size="small" @click="Cyclework" type="primary">布置周期作业</el-button>
                        <el-button size="small" @click="Temporary" type="primary" plain>布置其它作业</el-button>
                    </div>
                    <div class="label_button_box_right">
                        <el-input v-model="keyword" size="small" :clearable="true" @input="changKeyword" placeholder="请输入班级名称"></el-input>
                        <!-- <el-button type="primary">搜索</el-button> -->
                    </div>
                </div>
                <Skeleton v-if="loading" :skeleton_type="'image_text'"></Skeleton>

                <div class="_list" v-if="!loading">
                    <el-col v-if="!plan_data.length" :span="24">
                        <el-empty description="未创建班级"></el-empty>
                    </el-col>
                    <el-col v-for="(item,index) in plan_data" :key="index" :span="24">
                        <el-card shadow="hover">
                            <el-checkbox size="medium" style="float: left;margin-right: 10px;" @change="updateSelection(item.plan_id)" :key="item.plan_id"></el-checkbox>
                            <div class="list_thumb">
                                <img v-if="!item.image" src="../../../static/image/bjtp-mr4.png" />
                                <img v-if="item.image" :src="item.image" />
                                <img class="plan_tage" v-if="item.status == 1" src="../../../static/image/dlbj.png">
                                <img class="plan_tage" v-if="item.status == 0" src="../../../static/image/zrbj.png">
                            </div>
                            <div class="list_info">
                                <div class="list_info_text" style="font-weight: 700;">{{ item.name }}</div>
                                <div class="list_info_text">
                                    <span>年级: {{ item.grade_text }}</span>
                                    <span>学院: {{ item.college }}</span>
                                    <span>专业: {{ item.specialty }}</span>
                                </div>
                                <div class="list_info_text">人数: {{ item.number }}</div>
                            </div>
                            <div class="list_right">
                
                                <div class="list_right_bu">
                                    <el-button size="small" @click="workDetail(item.plan_id,item.name)" type="primary">查看作业</el-button>
                                </div>
                            </div>
    
                        </el-card>
                    </el-col>
        
                    <el-pagination
                        v-if="total_number"
                        @size-change="changeSize"
                        @current-change="changeNumber"
                        @prev-click="prev"
                        @next-click="next"
                        :page-size="form_data.pageSize"
                        :total="total_number">
                    </el-pagination>
                </div>

            </div>
            
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/teach";
import skeleton from "../../../untils/skeleton.vue";
export default {
    components:{
        'Skeleton':skeleton
    },
    data(){
        return{
            loading:true,
            plan_data:[],
            form_data:{
                page:1,
                pageSize:6,
                state:0
            },
            total_number:0,
            keyword:'',
            select_checkbox:[]
        }
    },
    activated() {

        this.getList()
    
    },
    methods:{
        
        getList(){
            // const loading = this.$loading({
            //     lock: true,
            //     text: '疯狂加载中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.5)'
            // });
            var params = this.form_data
            params.keyword = this.keyword
            Http.PlanList(params).then((res) => {
                this.loading = false
                //loading.close()
                this.plan_data = res.data.list
                this.total_number = res.data.count
            });
        },
        Cyclework(){
     
            if(!this.select_checkbox.length){
                this.$message({
                    showClose: true,
                    message: '请先选择班级',
                    type: 'error'
                });
                return false;
            }
            var plan = this.select_checkbox.join(',')
            //this.select_checkbox = []
            this.$router.push({name:'Cyclework',query:{plan:plan}});
        },

        Temporary(){
            if(!this.select_checkbox.length){
                this.$message({
                    showClose: true,
                    message: '请先选择班级',
                    type: 'error'
                });
                return false;
            }
            var _this = this
            var plan = this.select_checkbox.join(',')
            //this.select_checkbox = []
            _this.$router.push({name:'Temporary',query:{plan:plan}});
        },

        updateSelection(value){
            var row = this.select_checkbox
            if(row.length){
                if(row.indexOf(value)>-1){
                    row.splice(row.indexOf(value),1)
                }else{
                    row.push(value)
                }
            }else{
                row.push(value)
            }
            this.select_checkbox = row
        },

        // 上一页

        prev(e){
            this.form_data.page = e
            this.getList()
        },

        // 下一页
        next(e){
    
            this.form_data.page = e
            this.getList()
        },

        // 点击页码
        changeNumber(e){
            this.form_data.page = e
            this.getList()
        },

        // 前往指定页数
        changeSize(e){
            this.form_data.page = e
            this.getList()
        },
        // 搜索班级
        changKeyword(value){
            this.form_data.keyword = value
            this.getList();
        },
        workDetail(plan_id,name){
            this.$router.push({name:'Worklist',query:{plan_id:plan_id,plan_name:name}});
        },
    }
}
</script>
<style>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .label_button_box_right >>> .el-button{
        float: right;
        margin-top: 0px;
    }
    .label_button_box_right{
        float: right;
        width:21vw;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .label_button_box_right >>> .el-button{
        float: right;
        margin-top: 3px !important;
    }
    .label_button_box_right{
        float: right;
        width:19vw;
    }
}
</style>
<style scoped>

.top_label{
    width: 100%;
    height: 40px;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.list_thumb{
    float: left;
    width: 165px;
    height: 110px;
}

.list_thumb img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.list_info{
    height: 110px;
    float: left;
    box-sizing: border-box;
    padding-left: 15px;
}
.list_info_text{
    width: 100%;
    text-align: left;
    display: inline-block;
    margin-bottom: 10px;
}

.list_info_text span{
    display: inline-block;
    margin-right: 5px;
    
}
.list_right{
    float: right;
    width: 10vw;
    height: 5.5vw;
    line-height: 5.5vw;
}



.work_body >>> .el-card__body{
    float: left;
    width: 100%;
    padding-right: 25px;
    box-sizing: border-box;
}
.work_body >>> .el-col{
    margin-bottom: 20px;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:10px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;

}
.label_button_box_right >>> .el-input{

    width: 15vw;
}
.label_button_box >>> .el-checkbox{

    float: left;
    margin-right: 20px;
}
._list >>>.el-checkbox{
    
    margin-top: 2.2vw;
}
.list_thumb{
    position: relative;
}
.plan_tage{
    position: absolute;
    width: 60px !important;
    height: 38px !important;
    left: 0;
}
</style>