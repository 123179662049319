<!-- 应试英语选题 -->
<template>
    <div class="dialog_box">
        <el-dialog title="查找文章" :visible.sync="dialogFormVisible">
            <el-row>
                <el-button size="small" disabled type="text">选文类别</el-button>
                <span v-for="(item,index) in label" :class="`${item.select?'select':''}`" @click="selectLabel(index,item.index)" :key="index">{{ item.value }}</span>

            </el-row>

            <el-input v-model="search_data.title" @input="changeSearch" placeholder="请输入文章标题"></el-input>

            <el-table
           
                ref="multipleTable"
                :data="tableData_"
                tooltip-effect="dark"
                row-key="id"  
                @row-click="handleRowClick"
                style="width: 100%;margin-top: 10px;cursor: pointer;"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection"
                :reserve-selection="true"
                width="55">
                </el-table-column>
                <el-table-column
                prop="title"
                label="标题"
                
                >
                </el-table-column>
   
            </el-table>

            <el-pagination
                v-if="total_number"
                @size-change="changeSize"
                @current-change="changeNumber"
                @prev-click="prev"
                @next-click="next"
                :page-size="search_data.pageSize"
                :total="total_number">
            </el-pagination>
        </el-dialog>
        <el-dialog :title="`【${specialty.type_text}】${specialty.title}`" :visible.sync="dialogDetailVisible" @close="dialogDetaiClose">
            <div v-if="specialty.type == 6" style="text-align: left;font-size: 15px;line-height: 25px;" v-html="specialty.detail"></div>
            <div v-if="specialty.type != 3" style="text-align: left;font-size: 15px;line-height: 25px;" v-html="specialty.body"></div>
            <div class="first_question" v-for="(item,index) in specialtytest" :key="index">
                <h3>{{ index+1 }}. {{ item.question }}( {{ item.r_answer }} )</h3>
                <div style="cursor: pointer;">

                    <div class="question_list" v-for="(value,k) in item.answer" :key="k">
                        {{ value.name }}. {{ value.value }}
                    </div>
                        
                </div>
                
            </div>

            <div v-if="specialty.type == 7" v-html="specialty.answer"></div>
            <div v-if="specialty.type == 4">
                <span style="margin-top: 20px;" v-for="(spitem,spindex) in specialty.answer" :key="spindex">{{ spitem }}</span>
            </div>
            
        </el-dialog>
    </div>
</template>
<script>
import Http from "../src/apis/work";
export default {
    data(){
        return{
            dialogFormVisible:false,
            search_data:{
                type:0,
                page:1,
                pageSize:15
            },

            tableData_: [],
            multipleSelection:[],
            total_number:0,
            cispace_data: [],
            label:{},
            type:{},
            grade:{},
            dialogDetailVisible:false,
            specialtytest:[],
            specialty:{}
        }
    },

    methods:{
        handleRowClick(row,event){
            this.getDetail_(row.id)
            this.dialogFormVisible = false
            this.dialogDetailVisible = true
        },
        getDetail_(id){
            var params = {}
            params.id = id
            Http.specialtyDetail(params).then((res) => {
                this.specialty = res.data.specialty
                this.specialtytest = res.data.specialty_test
            });
        },
        dialogDetaiClose(){
            this.dialogFormVisible = true
        },
        readDialogShow(){
            console.log(1)
            this.dialogFormVisible = true
            this.changeSearch()
            this.getSpecialtyDiaoType()
        },

        handleSelectionChange(val) {

            this.$emit('handsSelect',val)
            
            //this.form_data.read_type = val
        },
        changeSearch(){
            
            Http.searchTemporarySpecialty(this.search_data).then((res) => {
                this.total_number = res.data.count
                this.tableData_ = res.data.data
        
            });
        },
        // 选择类别
        selectLabel(index,type){
            var label = JSON.parse(JSON.stringify(this.label))
            for(var i in label){
                if(i == index){
                    label[i].select = true
                }else{
                    label[i].select = false
                }
            }
            this.search_data.type = type
            this.label = label

            this.changeSearch()
        },


        // 获取应试题型、等级、分类
        getSpecialtyDiaoType(){
            
            Http.getCollection().then((res) => {
                var label = []
                for(let index in res.data){
                    let result = {}
                    result.key = index
                    result.value = res.data[index]
                    result.index = index
                    result.select = index == 0?true:false
                    label.push(result)
                }
                this.label = label

            });
        },
        // 上一页

        prev(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 下一页
        next(e){

            this.search_data.page = e
            this.changeSearch()
        },

        // 点击页码
        changeNumber(e){
 
            this.search_data.page = e
            this.changeSearch()
        },

        // 前往指定页数
        changeSize(e){
   
            this.search_data.page = e
            this.changeSearch()
        },
    }
}
</script>
<style scoped>
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.dialog_box >>> .el-dialog__header{
    background: #409EFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
}
.dialog_box >>> .el-dialog__title{
    color: #ffffff;
}
.dialog_box >>> .el-icon-close{
    color: #ffffff;
}
.dialog_box >>> .el-dialog{
    border-radius: 8px;
}
.dialog_box >>> .is-disabled{

    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.dialog_box {

    text-align: left;
}
.dialog_box >>> .el-button--default{
    margin-bottom: 10px;
}

.dialog_box span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.dialog_box >>> .el-pagination{
    text-align: center;
    margin-top: 1vw;
}
.question_list{
    display: inline-block;
    width: 100%;
    line-height: 26px;
    font-size: 14px;
}
</style>