import axios from '../services/request'

/* 班级列表 */
export const PlanList = (data = {}) => axios.get('api/teach/plan',data);

/* 班级码搜索班级 */
export const getPlan = (data = {}) => axios.get('api/teach/getPlan',data);

/* 加入班级 */
export const pushPlan = (data = {}) => axios.get('api/teach/pushPlan',data);

/* 创建班级 */
export const createPlan = (data = {}) => axios.post('api/teach/createPlan',data);

/* 获取年级 */
export const getYear = (data = {}) => axios.get('api/store/getYear',data);

/* 班级详情 */
export const planDetail = (data = {}) => axios.get('api/teach/planDetail',data);


/* 班级详情-搜索老师 */
export const addTeachSearch = (data = {}) => axios.get('api/teach/addTeachSearch',data);

/* 班级详情-添加老师 */
export const addTeach = (data = {}) => axios.get('api/teach/addTeach',data);


/* 班级详情-移除老师 */
export const removeTeach = (data = {}) => axios.post('api/teach/removeTeach',data);

/* 班级详情-导入学生 */
export const importStudent = (data = {}) => axios.post('api/teach/importStudent',data);

/* 班级详情-删除学生 */
export const removeStudent = (data = {}) => axios.post('api/teach/removeStudent',data);


/* 班级详情-获取学生学习记录 本年 */
export const resettingStudy = (data = {}) => axios.get('api/teach/resettingStudy',data);

/* 班级详情-重置学生学习记录 */
export const delSubmitanswe = (data = {}) => axios.post('api/teach/delSubmitanswe',data);

/* 班级详情-老师退出班级 */
export const qiutPlan = (data = {}) => axios.get('api/teach/qiutPlan',data);

/* 班级详情-手动添加学生 */
export const handCreate = (data = {}) => axios.post('api/teach/handCreate',data);

/* 班级详情-修改班级状态 */
export const pushRidao = (data = {}) => axios.get('api/teach/pushRidao',data);

/* 删除班级 */
export const delPlan = (data = {}) => axios.get('api/teach/delPlan',data);

/* 班级详情 */
export const getDetail = (data = {}) => axios.get('api/teach/getDetail',data);

/* 修改班级信息 */
export const updatePlan = (data = {}) => axios.post('api/teach/updatePlan',data);

/* 恢复班级 */
export const recoveryPlan = (data = {}) => axios.get('api/teach/recoveryPlan',data);

/* 获取分层教学 */
export const getStage = (data = {}) => axios.get('api/teachwork/getStage',data);

/* 获取分层教学学生成绩 */
export const studentStageWork = (data = {}) => axios.get('api/teachwork/studentStageWork',data);

/* 获取分层教学学生做题明细 */
export const stageSubmitList = (data = {}) => axios.get('api/teachwork/stageSubmitList',data);

/* 消息列表 */
export const tidingsList = (data = {}) => axios.get('api/teach/tidingsList',data);

/* 填写备注 */
export const addremark = (data = {}) => axios.post('api/teach/addremark',data);


export default{
    PlanList,
    getPlan,
    pushPlan,
    createPlan,
    getYear,
    planDetail,
    addTeachSearch,
    addTeach,
    removeTeach,
    importStudent,
    removeStudent,
    resettingStudy,
    delSubmitanswe,
    qiutPlan,
    handCreate,
    pushRidao,
    delPlan,
    getDetail,
    updatePlan,
    recoveryPlan,
    getStage,
    studentStageWork,
    stageSubmitList,
    tidingsList,
    addremark
}