import axios from '../services/request'

/* 获取应试题型 */
export const getCollection = (data = {}) => axios.get('api/teachwork/getCollection',data);

/* 获取应试题型 */
export const getCollection_ = (data = {}) => axios.get('api/teachwork/getCollection_',data);

/* 获取班级名称 */
export const getPlanName = (data = {}) => axios.post('api/teachwork/getPlanName',data);

/* 布置周期作业 */
export const createCyclework = (data = {}) => axios.post('api/teachwork/createCyclework',data);

/* 获取阅读题型 */
export const getReadType = (data = {}) => axios.get('api/teachwork/getReadType',data);

/* 阅读题型 - 搜索 */
export const searchTemporaryRead = (data = {}) => axios.get('api/teachwork/searchTemporaryRead',data);

/* 布置临时作业 */
export const createTemporaryWork = (data = {}) => axios.post('api/teachwork/createTemporaryWork',data);

/* 应试题型 - 搜索 */
export const searchTemporarySpecialty = (data = {}) => axios.get('api/teachwork/searchTemporarySpecialty',data);

/* 查看班级作业 */
export const getWork = (data = {}) => axios.get('api/teachwork/getWork',data);

/* 查看班级作业详情 */
export const getWorkDetail = (data = {}) => axios.get('api/teachwork/getWorkDetail',data);

/* 删除作业 */
export const delWork = (data = {}) => axios.get('api/teachwork/delWork',data);

/* 临时作业详情 */
export const getTemporaryWorkDetail = (data = {}) => axios.get('api/teachwork/getTemporaryWorkDetail',data);

/* 指定周期作业完成情况 */
export const doway = (data = {}) => axios.get('api/teachwork/doway',data);

/* 阅读详情 */
export const readDetail = (data = {}) => axios.get('api/read/readDetail',data);

/* 应试详情 */
export const specialtyDetail = (data = {}) => axios.get('api/specialty/specialtyDetail',data);
export default{
    getCollection,
    getCollection_,
    getPlanName,
    createCyclework,
    getReadType,
    searchTemporaryRead,
    createTemporaryWork,
    searchTemporarySpecialty,
    getWork,
    getWorkDetail,
    delWork,
    getTemporaryWorkDetail,
    doway,
    readDetail,
    specialtyDetail
}