<template>
    <div class="list_page">
        <layoutpage>
            <div class="content">
                <el-page-header @back="goBack" content="比赛文章"></el-page-header>
                <div class="content_page">

                        <el-col :span="8">
                            <el-card shadow="never">
                                <span class="top_name">个人排行</span>

                                <img :src="userInfo.avatar" style="width: 100px;height: 100px;border-radius:50%;margin-top: 30px;"/>
                                    
                                <div v-if="userMatch.number" style="margin-top: 15px;">
                                    <el-tooltip content="分数排名" placement="bottom" effect="light">
                                        <i class="el-icon-s-data"></i>
                                    </el-tooltip>
                                    <span>当前竞赛排名: 第</span>
                                    <span class="number_">{{ userMatch.number }}</span>
                                    <span>名</span>
                                </div>
                                <div v-if="!userMatch.number" style="margin-top: 15px;">
                                    <el-tooltip content="分数排名" placement="bottom" effect="light">
                                        <i class="el-icon-s-data"></i>
                                    </el-tooltip>
                                    <span>暂无排名</span>
                                </div>
                                    
                                <div class="container">
                                    <div class="item">
                                        <div style="display: inline-block;width: 100%;">
                                            <i style="font-size: 24px;color: #409eff;margin-right: 10px;float: left;" class="el-icon-odometer"></i> 
                                            <span style="font-weight: 700;color: #000000;float: left;width: 40px;line-height: 26px;">{{ userMatch.sign }}</span>
                                            <span style="float: left;width: 40px;">分</span>
                                        </div>
                                        <span style="font-size: 14px;color: #999999;">比赛总分数</span>
                                    </div>
                                    <div class="item">
                                        <div style="display: inline-block;width: 100%;">
                                            <i style="font-size: 24px;color: #409eff;margin-right: 10px;float: left;" class="el-icon-monitor"></i> 
                                            <span style="font-weight: 700;color: #000000;float: left;width: 40px;line-height: 26px;">{{ userMatch.matchsubmit_number }}</span>
                                            <span style="float: left;width: 40px;">题</span>
                                        </div>
                                        <span style="font-size: 14px;color: #999999;">比赛做题总数量</span>
                                    </div>
                                    <div class="item">
                                        <div style="display: inline-block;width: 100%;">
                                            <i style="font-size: 24px;color: #409eff;margin-right: 10px;float: left;" class="el-icon-document-checked"></i> 
                                            <span style="font-weight: 700;color: #000000;float: left;width: 40px;line-height: 26px;">{{ userMatch.score }}</span>
                                            <span style="float: left;width: 40px;margin-top: 3px;">%</span>
                                        </div>
                                        <span style="font-size: 14px;color: #999999;">比赛平均正确率</span>
                                    </div>
                                    <div class="item">
                                        <div style="display: inline-block;width: 100%;">
                                            <i style="font-size: 24px;color: #409eff;margin-right: 10px;float: left;" class="el-icon-time"></i> 
                                            <span style="font-weight: 700;color: #000000;float: left;width: 30px;line-height: 26px;">{{ userMatch.time }}</span>
                                            <span style="float: left;width: 40px;">分钟</span>
                                        </div>
                                        <span style="font-size: 14px;color: #999999;">比赛做题总时长</span>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
           

                        <el-col style="float: right;width: 64.5%;">
                            <el-card shadow="never">
                                <div class="screen">
                                    <el-row>
                                  
                                        <span @click="selectLabel(1)" :class="`${form.type == 1?'select':''}`">全部</span>
                                        <span @click="selectLabel(2)" :class="`${form.type == 2?'select':''}`">未完成</span>
                                        <span @click="selectLabel(3)" :class="`${form.type == 3?'select':''}`">已完成</span>
                                    </el-row>
                                    <el-table
                                        :data="tableData"
                                        :cell-style="{cursor: 'pointer'}"
                                        @row-click="handleRowClick"
                                        style="width: 100%">
                                        <el-table-column
                                            prop="title"
                                            label="文章"
                                            >
                                        </el-table-column>
                                        <el-table-column
                                            prop="is_do"
                                            label="状态"
                                            width="120">
                                            <template slot-scope="scope">
                                                <el-link :type="scope.row.isdo ? 'primary' : 'danger'" :underline="false">{{scope.row.do_text}}</el-link>
                                            </template>
                                        </el-table-column>
                                    </el-table>


                                    <el-pagination
                                        v-if="total_number"
                                        @size-change="changeSize"
                                        @current-change="changeNumber"
                                        @prev-click="prev"
                                        @next-click="next"
                                        :page-size="form.pageSize"
                                        :total="total_number"
                                        style="margin-top: 20px;">
                                    </el-pagination>
                                </div>
                        
                            </el-card>
                        </el-col>
                </div>
                <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                width="1200px"
                :before-close="handleClose">

                    <div class="question_box">
                        <div class="question_left">
                            <el-tabs v-model="readname">
                                <el-tab-pane label="阅读原文" name="first">
                                    <div style="text-align: left;line-height: 25px;font-size: 15px;" v-html="read.body"></div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <div class="question_right">
                            <el-tabs v-model="activeName1">
                                <el-tab-pane label="测试题目" name="first">
                                    <div v-if="readtest.length">
                                        <div class="first_question" v-for="(item,index) in readtest" :key="index">
                                            <span class="question">{{ index+1 }}. {{ item.question }}</span>
                                            <div style="cursor: pointer;"> 
                                                <el-col v-for="(value,k) in item.answer" :key="k" :span="22" >
                                                    <!-- 选择题 -->
                                                    <el-card :class="value.u_submit?(value.name == value.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.name?'right':'')" shadow="hover">
                                                        <div @click="optionsQuestion(item.id,value)">
                                                            <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                                        </div>
                                                    </el-card>
                                                </el-col>
                                            </div>
                                        </div>
                                        <el-button v-if="!submitanswers" type="primary" @click="submit" size="small">确认提交</el-button>
                                    </div>
                                </el-tab-pane>
                            
                            </el-tabs>
                        </div>
                    </div>
                </el-dialog>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/match";
export default {
    data(){
        return{
            fill:{},
            readtest:[],
            read:{},
            options:{},
            activeName1:'first',
            readname:'first',
            dialogVisible:false,
            tableData:[],
            form:{
                page:1,
                pageSize:20,
                type:1,
                mid:''
            },
            total_number:0,
            userInfo:{},
            userMatch:{},
            submitanswers:false,
            title:'',
            in_time:0,
            wid:''
        }
    },
    activated(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.form.mid = this.$route.query.id
        this.getData_()
        this.Usermatch_()
    },
    methods:{
        handleRowClick(row,event){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            this.title = row.title
            this.wid = row.wid
            var params = {}
            params.id = row.wid
            params.mid = this.form.mid
            Http.readDetail(params).then((res) => {
                loading.close()
                this.read = res.data.read
                this.readtest = res.data.readtest
                this.in_time = res.data.in_time
                this.dialogVisible = true
                this.submitanswers = res.data.submitanswers
            })
        },
        optionsQuestion(id,option){
            var params = JSON.parse(JSON.stringify(this.options))
            params[id] = option.name
            this.options = params
        },
        submit(){
            var params = {}
            params.wid = this.wid
            params.mid = this.form.mid
            params.in_time = this.in_time
            params.body = this.options
            Http.matchAnswer(params).then((res) => {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code?'success':'error'
                });
                if(res.code){
                    this.dialogVisible = false
                    this.getData_()
                }
            })
            
        },
        handleClose(){
            this.dialogVisible = false
        },
        selectLabel(index){
            this.form.type = index
            this.getData_()
        },
        getData_(){
            Http.List(this.form).then((res) => {
                this.tableData = res.data.matcharticl
                this.total_number = res.data.total_number
    
            })
        },
        Usermatch_(){
            var params = JSON.parse(JSON.stringify(this.form))
            delete params.page
            delete params.pageSize
            delete params.type
            Http.Usermatch(params).then((res) => {

                this.userMatch = res.data
            })
        },
        goBack(){
            window.history.back();
        },
         // 上一页
        prev(e){
            this.form.page = e
            this.getData_()
        },

        // 下一页
        next(e){
    
            this.form.page = e
            this.getData_()
        },

        // 点击页码
        changeNumber(e){
            this.form.page = e
            this.getData_()
        },

        // 前往指定页数
        changeSize(e){
            this.form.page = e
            this.getData_()
        },

    }
}
</script>
<style scoped>
.list_page .content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}
.content_page{
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
}
.content_page >>> .el-card__body{
    padding: 0 !important;
}
.top_name{
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #409eff;
    color: #ffffff;
}
.container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 20px;
}

.item {
    flex: 1 1 45%; 
    box-sizing: border-box;
    text-align: left;
    padding: 10px;

    margin: 5px;
    border-radius: 5px;
}
.content_page .screen{
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
    margin-bottom: 20px;
}
.content_page .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.content_page .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.number_{
    font-size: 40px;
    font-weight: 700;
    margin-left: 5px;
    margin-right: 5px;
    color: #f56c6c;
}
.el-icon-s-data{
    color: #409EFF;
    margin-right: 5px;
}
.question_box{

width: 100%;
height: 800px;
}
.question_left{
width: 50%;
float: left;
height: 800px;
overflow-y: auto;
box-sizing: border-box;
}
.question_left::-webkit-scrollbar {  
width: 10px; /* 滚动条的宽度 */  
}  

.question_left::-webkit-scrollbar-track {  
background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  

.question_left::-webkit-scrollbar-thumb {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
border-radius: 10px; /* 圆角效果 */  
}  

.question_left::-webkit-scrollbar-thumb:hover {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
position: sticky;
background: #ffffff;
top: 0;
z-index: 99;
}
.question_left >>> .el-tabs__content{

padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.right{
    background: #75a3e4 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    color: #ffffff;
}
.error{
    background: #ff6e6e !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    color: #ffffff;
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
}
</style>