<template>
    <div class="index_page">
        <layoutpage>
            <div class="privacy-header">
                <el-page-header @back="goBack" content="关于习传"></el-page-header>
            </div>

            <div class="privacy-content">
                <div v-html="content" style="font-size: 14px;"></div>
            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/common";
export default {
    data(){
        return{
            content:''
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        goBack(){
            window.history.back();
        },

        getDetail(){
            Http.Overview().then((res) => {
                this.content = res.data.content
            });
        }
    }
}
</script>
<style scoped>
.privacy-header >>> .el-page-header{
    width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 15px 12px;
}
.privacy-content{
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    background: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    margin-top: 15px;
}
</style>