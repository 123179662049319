import { render, staticRenderFns } from "./Layoutpage.vue?vue&type=template&id=21a8dc0d&scoped=true"
import script from "./Layoutpage.vue?vue&type=script&lang=js"
export * from "./Layoutpage.vue?vue&type=script&lang=js"
import style0 from "./Layoutpage.vue?vue&type=style&index=0&id=21a8dc0d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a8dc0d",
  null
  
)

export default component.exports