<template>
    <div class="page">
        <layout>
            <div class="main_body">
                <div class="top_label">
                    <span class="lable_name">成绩统计</span>

                </div>
                <div class="label_button_box">
      
                    <div class="label_button_box_right">
                        <el-input v-model="keyword" size="small" :clearable="true" @input="changKeyword" placeholder="请输入班级名称"></el-input>
                
                    </div>
                </div>
                <Skeleton v-if="loading" :skeleton_type="'image_text'"></Skeleton>
                <div v-if="!loading" class="_list">
                    <el-col v-if="!plan_data" :span="24">
                        <el-empty description="暂无数据"></el-empty>
                    </el-col>
                    <el-col v-for="(item,index) in plan_data" :span="24" :key="index">
                        <el-card shadow="hover">
                            <div class="list_thumb">
                                <img v-if="!item.image" src="../../../static/image/bjtp-mr4.png" />
                                <img v-if="item.image" :src="item.image" />
                                <img class="plan_tage" v-if="item.status == 1" src="../../../static/image/dlbj.png">
                                <img class="plan_tage" v-if="item.status == 0" src="../../../static/image/zrbj.png">
                            </div>
                            <div class="list_info">
                                <div class="list_info_text" style="font-weight: 700;">{{ item.name }}</div>
                                <div class="list_info_text" style="font-size: 15px;">
                                    <span>年级: {{ item.grade_text }}</span>
                                    <span>学院: {{ item.college }}</span>
                                    <span>专业: {{ item.specialty }}</span>
                                </div>
                                <div class="list_info_text" style="font-size: 15px;">人数: {{ item.number }}</div>
                                <div class="list_info_text" style="font-size: 15px;">班级码: {{ item.qcode }}</div>
                            </div>
                            <div class="list_right">
                    
                                <div class="list_right_bu">
                                    <el-button size="small" @click="statisticsDetail(item.plan_id,item.name)" type="primary">查看详情</el-button>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </div>
                    

                
            </div>
            
        </layout>
    </div>
</template>
<script>
import Http from "../../apis/student";
import skeleton from "../../../untils/skeleton.vue";
export default {
    components:{
        'Skeleton':skeleton
    },
    data(){
        return{
            plan_data:[],
            keyword:'',
            loading:true
        }
    },
    activated(){
        
        this.getPlan()
    },

    methods:{
        // 获取班级
        getPlan(){
            // const loading = this.$loading({
            //     lock: true,
            //     text: '疯狂加载中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.5)'
            // });
            var params = {}
            params.keyword = this.keyword
            Http.Plan(params).then((res) => {
                this.loading = false
                //loading.close()
                this.plan_data = res.data
            });

        },
        // 搜索班级
        changKeyword(){
            this.getPlan()
        },

        statisticsDetail(plan_id,plan_name){
            var _this = this
            _this.$router.push({name:'StudentStatisticsdetail',query:{plan_id:plan_id,plan_name:plan_name}});
        },
    }
}
</script>
<style>
@media screen and (min-width:320px) and (max-width:1199px) {

}
@media (min-width: 1200px) and (max-width: 1920px) {
    .label_button_box_right >>> .el-button{
        float: left;
        margin-top: 0px;
    }
    .label_button_box_right{
        float: left;
        width:21vw;
    }
}
@media (min-width: 1921px) and (max-width: 2600px) {
    .label_button_box_right >>> .el-button{
        float: left;
        margin-top: 3px !important;
    }
    .label_button_box_right{
        float: left;
        width:19vw;
    }
}
</style>
<style scoped>

.top_label{
    width: 100%;
    height: 1.6vw;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.list_thumb{
    float: left;
    width: 8.5vw;
    height: 110px;
}

.list_thumb img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.list_info{
    width: 30vw;
    float: left;
    box-sizing: border-box;
    padding-left: 14px;
}
.list_info_text{
    width: 100%;
    text-align: left;
    display: inline-block;
    line-height: 28px;
    font-size: 15px;
}

.list_info_text span{
    display: inline-block;
    margin-right: 5px;
    
}
.list_right{
    float: right;
    width: 10vw;
    line-height: 5.5vw;
}

.main_body >>> .el-card__body{
    float: left;
    width: 100%;
    padding-right: 25px;
    box-sizing: border-box;
}
.main_body >>> .el-col{
    margin-bottom: 20px;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:10px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: left;

}
.label_button_box_right >>> .el-input{
    float: left;
    width: 15vw;
}
.label_button_box >>> .el-checkbox{

    float: left;
    margin-right: 20px;
}
._list >>>.el-checkbox{
    
    margin-top: 2.2vw;
}
.list_thumb{
    position: relative;
}
.plan_tage{
    position: absolute;
    width: 60px !important;
    height: 38px !important;
    left: 0;
}
</style>